<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">

          <img src="@/assets/logo.png" style="width: 100px; background-color: transparent;" />
        </div>

        <div class="card-body mb-47 mt-2 white">
          <!-- <p class="t1 mb-3 text-center">
            <strong>ข้อกำหนดและเงื่อนไขการใช้บริการ Line Application “{{
              name_line
            }}” สำหรับการใช้งานแอปพลิเคชัน ในฐานะบุคคลทั่วไป</strong>
          </p> -->
          <p class="t1 mb-3 text-center">
            <strong>ข้อกำหนดและเงื่อนไขการอนุญาตให้ใช้ข้อมูล</strong>
          </p>
          <p class="t2" style="text-indent: 40px">

            จังหวัดเพชรบูรณ์ มีนโยบายในการขับเคลื่อนจังหวัดเพชรบูรณ์สู่การเป็นเมืองอัจฉริยะ จึงได้จัดทำระบบ <strong>Line
              Official Account</strong> ชื่อบัญชี <strong>“{{ name_line }}”</strong>
            พร้อมทั้งระบบการจัดเก็บข้อมูลในการสื่อสารด้านต่าง ๆ
            ระหว่างประชาชนกับจังหวัด และได้ดำเนินโครงการการจัดทำบัญชีข้อมูลจังหวัด (Area Data Catalog)
            ในการพัฒนาระบบข้อมูลให้เป็นดิจิทัล (Digitized Data) เพื่อนำไปสู่การเปิดเผยข้อมูลภาครัฐ (Open Data)
            และได้เผยแพร่ข้อมูลบนเว็บไซต์ ภายใต้ Domain Name ชื่อ <a
              href="https://phetchabun.gdcatalog.go.th"><strong>https://phetchabun.gdcatalog.go.th</strong></a>
            เพื่อประโยชน์ในการค้นหาและเข้าถึงข้อมูล
            ได้ง่าย ส่งเสริมให้เกิดการมีส่วนร่วมและตรวจสอบการดำเนินงานของหน่วยงานต่าง ๆ ได้โดยสะดวก
            พร้อมทั้งสามารถเชื่อมโยงข้อมูลผ่าน API ไปพัฒนานวัตกรรมในด้านต่าง ๆ ได้
            ผู้ประสงค์ใช้ข้อมูลสามารถเข้าใช้ข้อมูลนี้ได้ ภายใต้การยอมรับข้อกำหนดและเงื่อนไขในการอนุญาตให้ใช้ข้อมูล
            ดังต่อไปนี้



          </p>
          <ol class="t2 pl-5 sub-items mb-10" style="line-height: 180%">
            <li class="level-1">
              <strong><u>นิยามศัพท์</u></strong>
              <ol class="sub-items indent">
                <li>
                  <strong>การอนุญาต</strong> หมายถึง การอนุญาตให้ใช้ข้อมูล
                  ซึ่งวางข้อกำหนดและเงื่อนไขขั้นต่ำบางประการที่ผู้ใช้ข้อมูลจะต้องยอมรับก่อนการเข้าใช้ข้อมูลที่เผยแพร่
                </li>
                <li>
                  <strong>ข้อมูล</strong> หมายถึง ข้อมูลใด ๆ ที่มีลิขสิทธิ์หรือสิทธิอื่นใดของผู้อนุญาต ซึ่งได้แก่ ผลงาน
                  เนื้อหา Source Code และข้อมูลต่าง ๆ ทั้งทางวรรณกรรมและทางศิลปะกรรม ฯลฯ
                  และเป็นข้อมูลสารสนเทศหรือข้อมูลที่ถูกจัดเก็บแบบอิเล็กทรอนิกส์
                  ซึ่งผู้อนุญาตได้อนุญาตให้ผู้ใช้ข้อมูลเข้าใช้ข้อมูลภายใต้ข้อกำหนดและเงื่อนไขของการอนุญาต
                  เว้นแต่ข้อมูลนั้นเป็นข้อมูลที่มีกฎหมายกำหนดมิให้มีการเปิดเผย
                </li>
                <li>
                  <strong>ผู้ให้บริการข้อมูล</strong> หมายถึง บุคลากร เจ้าหน้าที่
                  หรือหน่วยงานใดที่ได้รับมอบหมายให้ทำหน้าที่ให้บริการข้อมูล
                </li>
                <li>
                  <strong>ผู้อนุญาต</strong> หมายถึง องค์กรหรือหน่วยงานต่างๆ ที่เป็นเจ้าของลิขสิทธิ์ ผู้ทรงสิทธิ
                  หรือผู้มีสิทธิในข้อมูล และอนุญาตให้ผู้ใช้ข้อมูลเข้าใช้ข้อมูลภายใต้ข้อกำหนดและเงื่อนไขของการอนุญาต
                </li>
                <li>
                  <strong> ผู้ใช้ข้อมูล </strong>หมายถึง บุคคล รวมถึงคณะบุคคล นิติบุคคล หน่วยงาน
                  และองค์กรใดก็ตามที่เข้าใช้ข้อมูลภายใต้ข้อกำหนดและเงื่อนไขของการอนุญาต
                </li>

                <li>
                  <strong> การใช้ข้อมูล </strong> หมายถึง การนำข้อมูลไม่ว่าทั้งหมดหรือบางส่วนไปใช้งาน
                  เพื่อวัตถุประสงค์ใดๆ
                  อันชอบด้วยกฎหมายของผู้ใช้ข้อมูล และภายใต้การยอมรับตามข้อกำหนดและเงื่อนไขของการอนุญาต

                </li>
              </ol>
            </li>

            <li class="level-1">
              <strong><u>ข้อกำหนดและเงื่อนไขของสัญญาอนุญาต </u></strong>
              <ol class="sub-items indent">
                ผู้อนุญาตอนุญาตให้ผู้ใช้ข้อมูลเข้าใช้สิทธิ (Non Exclusive License) ในข้อมูลดังกล่าวได้
                ภายใต้การยอมรับตามข้อกำหนดและเงื่อนไขของสัญญาอนุญาต โดยไม่จำกัดพื้นที่และไม่จำกัดเวลาในการใช้ข้อมูล
                ไม่ต้องขออนุญาตใช้สิทธิ และไม่ต้องเสียค่าตอบแทนการใช้สิทธิแต่อย่างใด โดยข้อกำหนดและเงื่อนไขดังต่อไปนี้
                จะไม่เป็นอุปสรรคหรือข้อจำกัดต่อเสรีภาพในการใช้ข้อมูลของผู้ใช้ข้อมูลทั้งสิ้น
              </ol>
            </li>

            <li class="level-1">
              <strong><u>สิทธิและเสรีภาพของผู้ใช้ข้อมูล</u></strong>
              <ol class="sub-items indent">
                <li>
                  คัดลอก เผยแพร่ แจกจ่าย และส่งข้อมูล เพื่อวัตถุประสงค์อันชอบด้วยกฎหมาย
                </li>
                <li>
                  ปรับใช้ข้อมูล โดยวิธีการหรือรูปแบบใด ๆ ซึ่งไม่เป็นการปรับเปลี่ยนเนื้อหาสาระของข้อมูล
                  เพื่อวัตถุประสงค์อันชอบด้วยกฎหมาย
                </li>
                <li>
                  ใช้ประโยชน์ในข้อมูล ซึ่งรวมถึง การรวมข้อมูลเข้ากับข้อมูลอื่น ๆ
                  หรือการนำข้อมูลมาเป็นส่วนหนึ่งของผลิตภัณฑ์หรือแอปพลิเคชันของผู้ใช้ข้อมูล ในเชิงสาธารณะประโยชน์
                  หรือในเชิงพาณิชย์เพื่อวัตถุประสงค์อันชอบด้วยกฎหมาย
                </li>
              </ol>
              <p class="t2" style="text-indent: 40px">
                ในการนำข้อมูลไม่ว่าทั้งหมดหรือบางส่วนไปใช้งาน เพื่อวัตถุประสงค์ใด ๆ อันชอบด้วยกฎหมายของผู้ใช้ข้อมูล
                ผู้ใช้ข้อมูลต้องอ้างอิงถึงผู้อนุญาต โดยใส่ข้อความเฉพาะของผู้ให้บริการข้อมูลที่ระบุถึงผู้อนุญาต
                พร้อมทั้งสร้างช่องทางเชื่อมโยงถึงการอนุญาตให้ใช้ข้อมูลนี้ด้วย อย่างไรก็ตาม
                ถ้าข้อมูลนั้นไม่ได้ระบุถึงผู้อนุญาต หรือเป็นข้อมูลที่เกิดจากการรวบรวมข้อมูลมาจากผู้อนุญาตที่หลากหลาย
                ให้ผู้ใช้ข้อมูลใช้รูปแบบการอ้างอิงโดยใช้ข้อความดังนี้
                “สิทธิการใช้ข้อมูล ภายใต้การอนุญาตให้ใช้ข้อมูลของ จังหวัดเพชรบูรณ์”
                โดยข้อกำหนดการอ้างอิงถึงผู้อนุญาต ดังกล่าวข้างต้น ถือเป็นข้อกำหนดและเงื่อนไข
                ที่เป็นสาระสำคัญของสัญญาอนุญาตนี้ หากผู้ใช้ข้อมูลไม่ปฏิบัติตาม
                ถือว่าการอนุญาตให้ใช้ข้อมูลตามสัญญาอนุญาตนี้ สิ้นสุดลงทันทีโดยอัตโนมัติ
              </p>

            </li>

            <li class="level-1">
              <strong><u>ข้อยกเว้น</u></strong>
              <p>การอนุญาตให้ใช้ข้อมูลตามสัญญาอนุญาตนี้ ไม่ครอบคลุมถึง </p>
              <ol class="sub-items indent">
                <li>
                  ข้อมูลส่วนบุคคลที่อยู่ในเนื้อหาข้อมูล
                </li>
                <li>
                  ข้อมูลที่ไม่ได้เผยแพร่โดยผู้ให้บริการข้อมูล หรือด้วยความยินยอมของผู้ให้บริการข้อมูล
                  หรือข้อมูลที่ไม่เผยแพร่ให้เข้าถึงภายใต้ พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562
                  และที่แก้ไขเพิ่มเติม (ถ้ามี) หรือภายใต้กฎหมายอื่นใดที่เกี่ยวข้องกับข้อมูลนั้น
                </li>
                <li>
                  โลโก้หรือตราสัญลักษณ์ของหน่วยงานของรัฐหรือของผู้ให้บริการข้อมูล และตราแผ่นดิน
                  ยกเว้นกรณีที่โลโก้และตราดังกล่าวเป็นส่วนหนึ่งของเอกสารหรือชุดข้อมูล
                </li>
                <li>
                  เครื่องหมายสัญลักษณ์ทางทหาร
                </li>
                <li>
                  สิทธิของบุคคลอื่น องค์กร หรือหน่วยงานอื่น ซึ่งผู้ให้บริการข้อมูลไม่มีอำนาจที่จะอนุญาต
                </li>
                <li>
                  สิทธิในทรัพย์สินทางปัญญาอื่นๆ ได้แก่ สิทธิบัตร เครื่องหมายทางการค้า ความลับทางการค้า ฯลฯ
                </li>
                <li>
                  เอกสารแสดงตัวตน ได้แก่ หนังสือเดินทาง บัตรประชาชน ฯลฯ
                </li>

              </ol>
            </li>

            <li class="level-1">
              <strong><u>ไม่รับรอง</u></strong>
              <p class="t2" style="text-indent: 40px">การอนุญาตให้ใช้ข้อมูลภายใต้สัญญาอนุญาตนี้
                ไม่ถือเป็นการให้ใช้สิทธิในข้อมูลลักษณะที่แสดงถึงสถานะอย่างเป็นทางการใด ๆ
                ระหว่างผู้ให้บริการข้อมูลและผู้รับข้อมูล
                และไม่ถือว่าผู้ให้บริการข้อมูลรับรองผู้ใช้ข้อมูลหรือการใช้ข้อมูลของผู้ใช้ข้อมูลแต่อย่างใด</p>
            </li>

            <li class="level-1">
              <strong><u>ไม่รับประกัน</u></strong>
              <p class="t2" style="text-indent: 40px">ข้อมูลที่ผู้ให้บริการข้อมูลอนุญาตให้ผู้ใช้ข้อมูลใช้เป็นข้อมูลดิบ
                ที่มีคุณลักษณะหรือคุณสมบัติอยู่ในสภาพเดิม โดยผู้ให้บริการข้อมูลไม่ได้ให้การรับรองในข้อมูล
                และไม่ถือว่าผู้ให้บริการข้อมูลและผู้ใช้ข้อมูลมีความผูกพันเป็นตัวแทนหรือหุ้นส่วนระหว่างกัน
                ตลอดจนผู้ให้บริการข้อมูลไม่รับประกันและไม่ต้องรับผิดชอบในการใช้ข้อมูลดังกล่าว
                ไม่ว่าภายใต้ข้อบังคับหรือกฎหมายใด </p>
              <p class="t2" style="text-indent:40px">ผู้ให้บริการข้อมูลไม่ต้องรับผิดชอบต่อข้อผิดพลาด
                และความไม่ครบถ้วนสมบูรณ์ของข้อมูล ตลอดจนไม่ต้องรับผิดชอบต่อความสูญเสีย หรือความเสียหายใดๆ
                ที่เกิดจากการใช้งานข้อมูลของผู้ใช้ข้อมูล </p>
              <p class="t2" style="text-indent:40px">จังหวัดเพชรบูรณ์และผู้ให้บริการข้อมูล
                ขอสงวนสิทธิ์ในการระงับชั่วคราวหรือยกเลิกการเผยแพร่ข้อมูลนี้ หรือการยกเลิกการให้บริการ
                หรือยกเลิกการให้ข้อมูลต่อไปไม่ว่ากรณีใด ๆ โดยไม่จำต้องบอกกล่าวล่วงหน้า และไม่ก่อให้เกิดความรับผิดชอบใด ๆ
                แก่ จังหวัดเพชรบูรณ์และผู้ให้บริการข้อมูล </p>

            </li>

            <li class="level-1">
              <strong><u>กฎหมายที่ใช้บังคับ</u></strong>
              <p class="t2" style="text-indent: 40px">การอนุญาตให้ใช้ข้อมูลนี้ อยู่ภายใต้กฎหมายแห่งราชอาณาจักรไทย
                และอยู่ภายใต้อำนาจในการตัดสินคดีของศาลไทย </p>
            </li>

            <li class="level-1">
              <strong><u>กฎหมายที่เกี่ยวข้อง</u></strong>
              <ol class="sub-items indent">
                <li>พระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562</li>
                <li>พระราชบัญญัติว่าด้วยการรักษาความมั่นคงปลอดภัยไซเบอร์ พ.ศ. 2562</li>
                <li>พระราชบัญญัติข้อมูลข่าวสารของราชการ พ.ศ. 2540</li>
                <li>พระราชบัญญัติว่าด้วยธุรกรรมทางอิเล็กทรอนิกส์ พ.ศ. พระราชบัญญัติว่าด้วยธุรกรรมทางอิเล็กทรอนิกส์ 2544
                  และที่แก้ไขเพิ่มเติม</li>
                <li>พระราชบัญญัติว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์ พ.ศ. 2550 และที่แก้ไขเพิ่มเติม</li>

              </ol>
            </li>

            <li class="level-1">
              <strong><u>การตรวจสอบที่มาของข้อมูล </u></strong>
              <ol class="sub-items indent">
                <li>ข้อมูลภายใต้ข้อกำหนดและเงื่อนไขของการอนุญาตให้ใช้ข้อมูล เป็นข้อมูลที่เผยแพร่บนเว็บไซต์ภายใต้ Domain
                  Name ชื่อ @phetchabun เท่านั้น </li>
                <li>ติดต่อผู้ให้บริการข้อมูล : สำนักงานสถิติจังหวัดเพชรบูรณ์ โทรศัพท์ : 0 5672 9773 Email:
                  saraban_phchabun@nso.mail.go.th</li>

              </ol>

            </li>

          </ol>

          <p class="t1 mb-3 text-center">
            <strong>นโยบายในการคุ้มครองข้อมูลส่วนบุคคล (Privacy Policy)</strong>
          </p>
          <p class="t2" style="text-indent: 40px">

            นโยบายนี้ จัดทำขึ้นเพื่อให้ผู้บริการได้ทราบและเข้าใจในนโยบายในการคุ้มครองข้อมูลส่วนบุคคลที่ จังหวัดเพชรบูรณ์
            ซึ่งต่อไปนี้เรียกว่า <strong>“{{ name_line }}”</strong> ได้เก็บ รวบรวม ใช้ เปิดเผย
            และเก็บรักษาข้อมูลส่วนบุคคลของผู้ใช้บริการ
            โดยเป็นไปตามอำนาจหน้าที่ และวัตถุประสงค์ในการให้บริการ ระบบงานกาชาดมะขามหวาน ผ่านทางโทรศัพท์เคลื่อนที่
            คอมพิวเตอร์ หรืออุปกรณ์อิเล็กทรอนิกส์

          </p>

          <ol class="t2 pl-5 sub-items mb-10" style="line-height: 180%">
            <li class="level-1">
              <strong><u>ขอบเขตของการเก็บรวบรวมข้อมูลส่วนบุคคล </u></strong>
              <p class="t2" style="text-indent: 40px"> จังหวัดเพชรบูรณ์ มีการเก็บรวบรวมข้อมูลส่วนบุคคลของผู้ใช้บริการ
                ด้วยวิธีที่ชอบด้วยกฎหมายและเป็นธรรม และจัดเก็บข้อมูลเท่าที่จำเป็นแก่การให้บริการ
                ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์ ตามที่กฎหมายกำหนดเท่านั้น โดย จังหวัดเพชรบูรณ์
                จะแจ้งให้ผู้ใช้บริการทราบ และขอความยินยอมก่อนเก็บรวบรวมข้อมูลนั้น เว้นแต่เป็นกรณีที่กฎหมายกำหนด
                และ/หรือในกรณีอื่น ๆ ตามที่กำหนดไว้ในนโยบายฉบับนี้ </p>
              <p class="t2" style="text-indent: 40px"> อย่างไรก็ตาม
                ในบางกรณีอาจมีซอฟต์แวร์บางประเภทของบุคคลภายนอกที่ติดตั้งอยู่ในเครื่องคอมพิวเตอร์หรืออุปกรณ์อิเล็กทรอนิกส์ของผู้ใช้บริการ
                อาจทำให้สามารถเข้าถึงข้อมูลส่วนบุคคลที่อยู่ในเครื่องคอมพิวเตอร์
                หรืออุปกรณ์อิเล็กทรอนิกส์ของผู้ใช้บริการได้ ซึ่งซอฟต์แวร์ของบุคคลอื่น ๆ เหล่านั้น
                อาจมีนโยบายความเป็นส่วนตัวของตนเอง
                จังหวัดเพชรบูรณ์ขอแนะนำให้ผู้ใช้บริการเข้าไปอ่านและศึกษานโยบายความเป็นส่วนตัวดังกล่าวด้วย และ
                จังหวัดเพชรบูรณ์จะไม่ขอรับผิดชอบต่อแนวปฏิบัติเรื่องความเป็นส่วนตัวในบริการดังกล่าวแต่อย่างใด </p>
            </li>


            <li class="level-1">
              <strong><u>คุณภาพของข้อมูลส่วนบุคคล</u></strong>
              <p class="t2" style="text-indent: 40px">ระบุเงื่อนไขการให้ข้อมูล ตัวอย่างเช่น</p>
              <p class="t2" style="text-indent: 40px">ในการใช้บริการ
                ระบบงานกาชาดมะขามหวานผู้ใช้บริการจะต้องให้ข้อมูลที่เป็นความจริง ไม่บิดเบือน และถูกต้องสมบูรณ์
                รวมถึงผู้ใช้บริการต้องปรับปรุงข้อมูลของผู้ใช้บริการให้เป็นปัจจุบันอยู่เสมอ ทั้งนี้
                ผู้ใช้บริการต้องไม่นำข้อมูลของบุคคลอื่นมาใช้ในการลงทะเบียนเพื่อใช้บริการ ระบบงานกาชาดมะขามหวานของ
                จังหวัดเพชรบูรณ์</p>
              <p class="t2" style="text-indent: 40px">โดย จังหวัดเพชรบูรณ์
                จะรวบรวมและจัดเก็บข้อมูลส่วนบุคคลของผู้ใช้บริการ เพื่อนำไปใช้ประโยชน์ต่อการให้บริการ
                ระบบงานกาชาดมะขามหวานซึ่งเป็นไปตามอำนาจหน้าที่และวัตถุประสงค์ในการให้บริการ ระบบงานกาชาดมะขามหวานของ
                จังหวัดเพชรบูรณ์ตามที่กฎหมายกำหนดเท่านั้น และให้ความสำคัญถึงความถูกต้องครบถ้วน
                และเป็นปัจจุบันของข้อมูลส่วนบุคคลที่จัดเก็บ</p>
            </li>

            <li class="level-1">
              <strong><u> วัตถุประสงค์ในการจัดเก็บข้อมูลส่วนบุคคล </u></strong>
              <p class="t2" style="text-indent: 40px">จังหวัดเพชรบูรณ์จะรวบรวม จัดเก็บ
                และใช้ข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ในการให้บริการ
                โดยจะแจ้งให้ผู้ใช้บริการทราบและขอความยินยอมก่อนให้บริการนั้น ทั้งนี้
                หากมีการเปลี่ยนแปลงวัตถุประสงค์ในการจัดเก็บข้อมูลส่วนบุคคล
                จังหวัดเพชรบูรณ์จะทำการบันทึกแก้ไขเพิ่มเติมไว้เป็นหลักฐานด้วย</p>

            </li>

            <li class="level-1">
              <strong><u>การจัดเก็บและวิธีการใช้ข้อมูลส่วนบุคคล</u></strong>
              <p class="t2" style="text-indent: 40px">ในการให้บริการ ระบบงานกาชาดมะขามหวานของ
                จังหวัดเพชรบูรณ์จะมีการจัดเก็บ เก็บรักษา และใช้ข้อมูลของผู้ใช้บริการ ดังนี้ </p>

              <ol class="sub-items indent">
                <li>ข้อมูลส่วนบุคคล (Personal Information) หมายถึงข้อมูลใด ๆ
                  หรือข้อมูลที่ประกอบกันและสามารถเชื่อมโยงถึงผู้ใช้บริการ
                  และสามารถถูกนำไปใช้ในการระบุตัวตนของผู้ใช้บริการ โดยมีข้อมูลส่วนตัว อาจหมายรวมถึง</li>
                <p class="t2" style="text-indent: 60px">• ข้อมูลที่ผู้ใช้บริการเปิดบัญชีผู้ใช้งานบริการ
                  ระบบงานกาชาดมะขามหวานของจังหวัดเพชรบูรณ์ ได้แก่ ชื่อ นามสกุล หมายเลขโทรศัพท์ อีเมล </p>
                <p class="t2" style="text-indent: 60px">• ข้อมูลที่ผู้ใช้บริการได้แลกเปลี่ยนข้อมูล (Shared Information)
                  กับผู้ใช้บริการอื่น ๆ ผ่านบริการ ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์
                  และข้อมูลที่ผู้ใช้บริการได้มีการเก็บรักษาอยู่ในบริการ
                  ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์
                </p>



              </ol>


              <ol class="sub-items indent">
                <li>ข้อมูลตำแหน่งที่ตั้ง (Location Data) เป็นข้อมูลที่
                  จังหวัดเพชรบูรณ์จัดเก็บเกี่ยวกับตำแหน่งการใช้บริการของผู้ใช้บริการ
                  ระบบงานกาชาดมะขามหวานเมื่อผู้ใช้บริการได้เปิดใช้บริการระบุตำแหน่ง (Location-Enabled Service) โดยรวมถึง
                </li>
                <p class="t2" style="text-indent: 60px">
                  • ตำแหน่งของอุปกรณ์สื่อสารหรืออุปกรณ์อิเล็กทรอนิกส์ที่ผู้ใช้บริการได้ใช้บริการ
                  ระบบงานกาชาดมะขามหวานของ
                  จังหวัดเพชรบูรณ์ เช่น จาก GPS, WiFi, ระบบเข็มทิศ (Compass), ระบบตรวจจับการเคลื่อนไหว (Accelerometer)
                  หรือระบบตรวจจับอื่น ๆ ที่อุปกรณ์สื่อสารของผู้ใช้บริการใช้อยู่</p>
                <p class="t2" style="text-indent: 60px">• IP Address
                  ของอุปกรณ์หรือผู้ให้บริการอินเทอร์เน็ตที่ผู้ใช้บริการได้ใช้เพื่อเข้าบริการ ระบบงานกาชาดมะขามหวานของ
                  จังหวัดเพชรบูรณ์
                </p>
                <p class="t2" style="text-indent: 60px">•
                  ข้อมูลอื่นใดที่ถูกสร้างขึ้นโดยผู้ใช้บริการหรือโดยบุคคลอื่นใดซึ่งสามารถทำให้
                  จังหวัดเพชรบูรณ์รับรู้ตำแหน่งของผู้ใช้บริการ เช่น ข้อมูลบัญชีที่ระบุว่าผู้ใช้บริการอยู่ที่ใด
                  และข้อมูลที่มีการแลกเปลี่ยนกัน
                  ซึ่งผู้ใช้บริการหรือผู้ใช้งานอื่นได้โพสต์ขึ้นเพื่อระบุตำแหน่งของผู้ใช้บริการ เช่น ข้อมูล Geotag
                  ซึ่งฝังอยู่กับรูปที่ผู้ใช้บริการได้สร้างขึ้น
                </p>
                <p class="t2" style="text-indent: 60px">• หากผู้ใช้บริการได้ให้ข้อมูลกับ
                  จังหวัดเพชรบูรณ์เกี่ยวกับข้อมูลตำแหน่งที่ตั้งของผู้ใช้บริการไว้ก่อนหน้านี้และไม่ต้องการให้
                  จังหวัดเพชรบูรณ์เข้าถึงข้อมูลดังกล่าว ผู้ใช้บริการสามารถดำเนินการได้เองผ่านทางการตั้งค่าการใช้งาน
                </p>
              </ol>


              <ol class="sub-items indent">
                <li>ข้อมูลจราจรทางคอมพิวเตอร์ (Log Data) เป็นข้อมูลทางเทคนิคซึ่งถูกจัดเก็บโดยอัตโนมัติ
                  เมื่อผู้ใช้บริการได้ใช้บริการ ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์ ไม่ว่าจะผ่านทางการใช้คุกกี้
                  (Cookies) เว็บบีคอน
                  (Web beacons) และไฟล์ข้อมูลจราจรทางคอมพิวเตอร์ (log files) หรือข้อมูลอื่นใด รวมถึง
                </li>
                <p class="t2" style="text-indent: 60px">• ข้อมูลทางเทคนิค เช่น ผู้ให้บริการโทรศัพท์เคลื่อนที่ (Mobile
                  carrier) ข้อมูลการตั้งค่า (Configuration Information) ที่ถูกกำหนดขึ้นโดยเว็บเบราเซอร์ของผู้ใช้บริการ
                  หรือโปรแกรมอื่นใดที่ผู้ใช้บริการได้ใช้เพื่อเข้าสู่บริการ ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์ IP
                  Address ของผู้ใช้บริการ เวอร์ชั่นและหมายเลขเครื่องของอุปกรณ์สื่อสารของผู้ใช้บริการ</p>
                <p class="t2" style="text-indent: 60px">• ข้อมูลที่ผู้ใช้บริการได้มีการค้นหา หรือตรวจดูในขณะที่ใช้บริการ
                  ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์ เช่น ข้อความที่ใช้ในการค้นหา ข้อมูลสื่อสังคมออนไลน์ (Social
                  Media) ที่ผู้ใช้บริการได้เข้า และรายละเอียดของข้อมูลต่าง ๆ
                  และเนื้อหาที่ผู้ใช้บริการได้เข้าถึงหรือร้องขอผ่านบริการ ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์
                </p>
                <p class="t2" style="text-indent: 60px">•
                  ข้อมูลการติดต่อสื่อสารที่ผู้ใช้บริการได้สร้างขึ้นผ่านการใช้บริการ ระบบงานกาชาดมะขามหวานของ
                  จังหวัดเพชรบูรณ์ เช่น ผู้คนที่มีการติดต่อด้วย เวลาในการติดต่อ ข้อมูลและระยะเวลาในการติดต่อ
                </p>


                <p class="t2" style="text-indent: 60px">
                  A.1. ข้อมูลที่อาจมีการเก็บเพิ่มเติมจากบริการเสริม เพื่อให้การใช้บริการ
                  ระบบงานกาชาดมะขามหวานมีความสะดวกมากยิ่งขึ้น
                  จังหวัดเพชรบูรณ์อาจจัดให้มีบริการเสริมเพื่อให้ง่ายต่อการเข้าถึงบัญชีผู้ใช้งานบนอุปกรณ์สื่อสารหรืออุปกรณ์อิเล็กทรอนิกส์ต่างๆ
                  และช่วยให้ผู้ใช้บริการสามารถค้นหาเพื่อน คนรู้จัก และรู้สึกเพลิดเพลินไปกับการใช้บริการ
                  ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์ โดยผู้ใช้บริการอาจต้องให้ข้อมูลเพิ่มเติมกับ จังหวัดเพชรบูรณ์
                  เพื่อใช้บริการเสริมเหล่านี้
                </p>
              </ol>

              <p class="t2" style="text-indent: 40px">
                สำหรับข้อมูลส่วนบุคคลที่ผู้ใช้บริการได้ให้เพื่อลงทะเบียน หรือที่สร้างขึ้นเพื่อรับ ส่ง แลกเปลี่ยน
                หรือเก็บรักษาผ่านบริการ ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์
                หมายถึงผู้ใช้บริการได้ให้ความยินยอมในการโอนและจัดเก็บข้อมูลส่วนบุคคลของผู้ใช้บริการในเซิร์ฟเวอร์ของ
                จังหวัดเพชรบูรณ์แล้ว และผู้ใช้บริการได้ยอมรับและตกลงว่าข้อมูลส่วนบุคคลดังกล่าว เป็นสิทธิและกรรมสิทธิ์ของ
                จังหวัดเพชรบูรณ์ ซึ่ง จังหวัดเพชรบูรณ์ จะให้ความคุ้มครองข้อมูลดังกล่าวอย่างดีที่สุด
                และเพื่อความปลอดภัยในข้อมูลส่วนบุคคลของผู้ใช้บริการ
                ผู้ใช้บริการต้องปฏิบัติตามข้อตกลงการใช้งานและนโยบายต่างๆ ที่เกี่ยวกับการใช้บริการ
                ระบบงานกาชาดมะขามหวานของ
                จังหวัดเพชรบูรณ์อย่างเคร่งครัด


                ทั้งนี้ ข้อมูลส่วนบุคคลที่ จังหวัดเพชรบูรณ์ ได้มีการเก็บรักษานี้ จังหวัดเพชรบูรณ์
                อาจนำไปใช้เพื่อวัตถุประสงค์ต่าง ๆ ดังนี้</p>
              <p class="t2" style="text-indent: 40px">
                • เพื่อให้บริการกับผู้ใช้บริการผ่านทางโทรศัพท์เคลื่อนที่ คอมพิวเตอร์ หรืออุปกรณ์อิเล็กทรอนิกส์
                และเพื่ออนุญาตให้ผู้ใช้บริการ สามารถรับส่งข้อมูลหรือเนื้อหาต่างๆ ระหว่างผู้ใช้บริการกับผู้ใช้บริการอื่น
                ๆ</p>
              <p class="t2" style="text-indent: 40px">
                • เพื่อใช้ในการยืนยันตัวตนของผู้ใช้บริการ</p>
              <p class="t2" style="text-indent: 40px">
                • เพื่ออนุญาตให้ผู้ใช้บริการใช้บัญชีผู้ใช้งานบนหลายอุปกรณ์ (Multiple Devices)
                และสามารถโอนถ่ายบัญชีของผู้ใช้บริการไปยังอุปกรณ์อื่น ๆ ได้
              </p>
              <p class="t2" style="text-indent: 40px">
                • เพื่อตรวจจับและตรวจสอบปัญหาการใช้งานบัญชีโดยไม่มีสิทธิ (Unauthorized Use of Account)
                หรือการใช้บัญชีในการฉ้อโกง (Fraud) หรือใช้ในทางมิชอบ (Abusive)
              </p>
              <p class="t2" style="text-indent: 40px">
                • เพื่อปรับปรุงการให้บริการให้ดียิ่งขึ้น
              </p>
              <p class="t2" style="text-indent: 40px">

                • เพื่อให้ จังหวัดเพชรบูรณ์ สามารถช่วยเหลือสนับสนุนผู้ใช้บริการ (Customer Support) ในการใช้บริการ
                ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์ และเพื่อตอบสนองต่อปัญหาจากการใช้บริการ
                ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์ ได้อย่างมีประสิทธิภาพ
              </p>
              <p class="t2" style="text-indent: 40px">
                • เพื่อให้ จังหวัดเพชรบูรณ์ สามารถใช้ในการสำรวจความพึงพอใจของผู้ใช้บริการได้
              </p>
              <p class="t2" style="text-indent: 40px">
                • เพื่อให้ จังหวัดเพชรบูรณ์ สามารถให้บริการข้อมูลเกี่ยวกับบริการ ระบบงานกาชาดมะขามหวานของ
                จังหวัดเพชรบูรณ์ ได้
              </p>
              <p class="t2" style="text-indent: 40px">
                • เพื่อให้ จังหวัดเพชรบูรณ์ สามารถให้บริการข้อมูลเกี่ยวกับบริการ ระบบงานกาชาดมะขามหวานของ
                จังหวัดเพชรบูรณ์ ได้
              </p>
              <p class="t2" style="text-indent: 40px">
                • เพื่อแจ้งผู้ใช้บริการในข้อมูลที่สำคัญเกี่ยวกับการใช้บริการ (ถ้าจำเป็น)
              </p>
              <p class="t2" style="text-indent: 40px">

                • เพื่อรวบรวมข้อมูลสถิติต่างๆ เกี่ยวกับการใช้บริการ ระบบงานกาชาดมะขามหวาน เพื่อให้ จังหวัดเพชรบูรณ์
                สามารถปรับปรุงบริการ ระบบงานกาชาดมะขามหวานให้สอดคล้องตามกฎหมายที่เกี่ยวข้อง
                อย่างไรก็ดี ในกรณีที่ จังหวัดเพชรบูรณ์ จะมีการใช้ข้อมูลส่วนบุคคลของผู้ใช้บริการ
                เพื่อวัตถุประสงค์อื่นใดเป็นการเฉพาะนอกเหนือจากที่กล่าวมาข้างต้น จังหวัดเพชรบูรณ์
                จะดำเนินการสอบถามผู้ใช้บริการในการใช้ข้อมูลของผู้ใช้บริการเป็นกรณีๆ ไป หรือใช้วิธีการอื่นใด
                เพื่อให้ผู้ใช้บริการได้รับทราบและยินยอมก่อนที่ จังหวัดเพชรบูรณ์ จะมีการดำเนินการใด ๆ
                เกี่ยวกับข้อมูลของผู้ใช้บริการ
              </p>


            </li>

            <li class="level-1">
              <strong><u> ข้อจำกัดในการนำข้อมูลส่วนบุคคลไปใช้</u></strong>
              <p class="t2" style="text-indent: 40px">

                จังหวัดเพชรบูรณ์ จะไม่เปิดเผยข้อมูลส่วนบุคคลที่ได้จัดเก็บรวบรวมไว้
                นอกเหนือจากวัตถุประสงค์ในการรวบรวมและจัดเก็บข้อมูล
                เว้นแต่จะได้รับความยินยอมจากเจ้าของข้อมูลหรือตามคำสั่งศาล และกำหนดให้ผู้รับจ้าง (outsource)
                เก็บรักษาความลับและความปลอดภัยของข้อมูลของผู้ใช้บริการ
                โดยห้ามนำข้อมูลไปใช้นอกเหนือจากที่กำหนดให้ดำเนินการเท่านั้น
              </p>

            </li>
            <li class="level-1">
              <strong><u> การรักษาความมั่นคงปลอดภัย</u></strong>
              <p class="t2" style="text-indent: 40px">
                เพื่อเป็นการปกป้องคุ้มครองข้อมูลส่วนบุคคลของผู้ใช้บริการให้มีความมั่นคงปลอดภัย
                และป้องกันไม่ให้ผู้อื่นมีสิทธิเข้าถึงข้อมูล รวมถึงรักษาความถูกต้องของข้อมูล
                หากผู้ใช้บริการได้มีการแบ่งปันหรือเปิดเผยรหัสผ่านหรือข้อมูลส่วนตัวของผู้ใช้บริการให้กับบุคคลอื่น
                หรือแม้ว่ากรณีใด ๆ
                ที่ผู้ใช้บริการไม่สามารถรักษาความลับในรหัสผ่านส่วนตัวหรือไม่สามารถควบคุมการใช้งานของรหัสผ่านของผู้ใช้บริการได้
                ผู้ใช้บริการจะต้องเป็นผู้รับผิดชอบสำหรับการกระทำใด ๆ
                ที่เกิดขึ้นภายใต้ชื่อหรือบัญชีนั้นโดยถือว่าเป็นการกระทำโดยผู้ใช้บริการเอง
                และต้องรับผิดชอบตามกฎหมายในฐานะที่ผู้ใช้บริการเองและต้องรับผิดตามกฎหมายในฐานะที่ผู้ใช้บริการเป็นเจ้าของบัญชี
              </p>
              <p class="t2" style="text-indent: 40px">
                นอกจากนี้ จังหวัดเพชรบูรณ์ ขอให้ข้อมูลกับผู้ใช้บริการว่า
                ปัจจุบันยังไม่มีวิธีการรับส่งข้อมูลทางอิเล็กทรอนิกส์หรือวิธีการจัดเก็บข้อมูลที่สมบูรณ์แบบหรือไม่มีช่องโหว่ที่ไม่สามารถผ่านเข้าไปได้
                ประกอบกับอาจมีบุคคลภายนอกที่พยายามดักข้อมูลหรือทำการใดๆ
                เพื่อเข้าถึงข้อมูลระหว่างการส่งข้อมูลหรือเข้ามาในระบบของผู้ใช้บริการหรือของ จังหวัดเพชรบูรณ์
                อย่างไม่มีสิทธิตามกฎหมาย และบุคคลดังกล่าวอาจนำข้อมูส่วนบุคคลของผู้ใช้บริการไปใช้อย่างไม่ถูกต้อง
                แต่อย่างไรก็ดี จังหวัดเพชรบูรณ์ มีมาตรการในการรักษาความั่นคงปลอดภัยข้อมูลส่วนบุคคลอย่างเหมาะสม
                เพื่อป้องกันมิให้ข้อมูลสูญหาย หรือมีการเข้าถึง ทำลาย ใช้ เปลี่ยนแปลง แก้ไข
                หรือเปิดเผยข้อมูลส่วนบุคคลโดยไม่ได้รับอนุญาต

              </p>

            </li>

            <li class="level-1">
              <strong><u>การมีส่วนร่วมของเจ้าของข้อมูล</u></strong>
              <p class="t2" style="text-indent: 40px">

                จังหวัดเพชรบูรณ์ จะเปิดเผยรายละเอียดข้อมูลส่วนบุคคล ก็ต่อเมื่อได้รับคำร้องขอจากเจ้าของข้อมูล
                ผู้สืบสิทธิ์
                ทายาท ผู้แทนโดยชอบธรรม หรือผู้พิทักษ์ตามกฎหมาย โดยส่งคำร้องขอผ่าน สำนักงานสถิติจังหวัดเพชรบูรณ์
                ทางจดหมายอิเล็กทรอนิกส์ saraban_phchabun@nso.mail.go.th และ จังหวัดเพชรบูรณ์
                จะดำเนินการให้แล้วเสร็จภายใน
                30 วัน นับตั้งแต่วันที่ได้รับคำร้องขอ

              </p>
              <p class="t2" style="text-indent: 40px">
                ในกรณีที่เจ้าของข้อมูล ผู้สืบสิทธิ์ ทายาท ผู้แทนโดยชอบธรรม หรือผู้พิทักษ์ตามกฎหมาย
                มีการคัดค้านการจัดเก็บ
                ความถูกต้อง หรือการกระทำใดๆ เช่น การแจ้งดำเนินการปรับปรุงแก้ไขข้อมูลส่วนบุคคล หรือลบข้อมูลส่วนบุคคล ฯลฯ
                จังหวัดเพชรบูรณ์ จะดำเนินการบันทึกหลักฐานคำคัดค้านดังกล่าวไว้เป็นหลักฐานด้วย
                ทั้งนี้ จังหวัดเพชรบูรณ์ อาจปฏิเสธสิทธิ์ตามวรรคสองได้ ตามกรณีที่กฎหมายกำหนด
                หรือในกรณีที่ข้อมูลส่วนบุคคลของผู้ใช้บริการถูกทำให้ไม่ปรากฏชื่อหรือสิ่งบอกลักษณะอันสามารถระบุตัวผู้ใช้บริการนั้นได้

              </p>
            </li>

            <li class="level-1">
              <strong><u> ความรับผิดชอบของบุคคลซึ่งทำหน้าที่ควบคุมข้อมูลส่วนบุคคล</u></strong>
              <p class="t2" style="text-indent: 40px">
                จังหวัดเพชรบูรณ์ การกําหนดให้เจ้าหน้าที่ที่เกี่ยวข้องในการจัดเก็บ ใช้
                และเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้บริการ
                ต้องปฏิบัติตามนโยบายนี้อย่างเคร่งครัด
              </p>
              <strong> บันทึกผู้เข้าชมเว็บ (Log Files)</strong><br>
              <p class="t2" style="text-indent: 40px">

                การใช้บริการ ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์ จะเก็บบันทึกการเข้า-ออก
                และระหว่างการเข้าใช้บริการ
                ระบบงานกาชาดมะขามหวานของผู้ใช้บริการโดยอัตโนมัติที่สามารถเชื่อมโยงข้อมูลดังกล่าวกับข้อมูลที่ระบุตัวบุคคลได้
                ทั้งนี้ เป็นไปตามพระราชบัญญัติว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์ พ.ศ. 2550 และที่แก้ไขเพิ่มเติม

              </p>

              <strong>การใช้คุกกี้ (Cookies) </strong><br>
              <p class="t2" style="text-indent: 40px">

                การให้บริการ ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์ มีการใช้คุกกี้หรือเทคโนโลยีอื่นที่คล้ายคลึงกัน
                เพื่อเพิ่มมาตรการด้านความมั่นคงปลอดภัย และสามารถรักษา session เมื่อมีการใช้บริการ
                ระบบงานกาชาดมะขามหวานรวมถึงช่วยจดจำข้อมูลเกี่ยวกับเบราว์เซอร์และการตั้งค่าของผู้ใช้บริการด้วย
                ซึ่งผู้ใช้บริการสามารถที่จะยอมรับหรือไม่รับคุ้กกี้ก็ได้ ในกรณีที่เลือกที่จะไม่รับคุ้กกี้ บริการ
                ระบบงานกาชาดมะขามหวานอาจจะไม่สามารถให้บริการนั้นได้

              </p>
            </li>

            <li class="level-1">
              <strong><u> การเปลี่ยนแปลงแก้ไขนโยบายในการคุ้มครองข้อมูลส่วนบุคคล</u></strong>
              <p class="t2" style="text-indent: 40px">
                การปรับปรุงหรือเปลี่ยนแปลงเงื่อนไขของนโยบายคุ้มครองข้อมูลส่วนบุคคลนี้ จังหวัดเพชรบูรณ์
                มีสิทธิที่จะพิจารณาแก้ไขเปลี่ยนแปลงเมื่อใดก็ได้ตามที่ จังหวัดเพชรบูรณ์เห็นสมควร และ จังหวัดเพชรบูรณ์
                จะทำการแจ้งให้ผู้ใช้บริการทราบผ่านทางอีเมล์หรือช่องทางอื่นใดที่ จังหวัดเพชรบูรณ์เห็นสมควร
                หรือผ่านทางผู้ดูแลระบบ (Admin) ของหน่วยงานของผู้ใช้บริการ โดยมีวันที่ของเวอร์ชั่นล่าสุดกำกับอยู่ตอนท้าย
                และขอให้โปรดตรวจสอบเป็นระยะ ๆ ว่านโยบายในการคุ้มครองข้อมูลส่วนบุคคลนี้ มีการเปลี่ยนแปลงหรือไม่
                โดยเฉพาะก่อนที่ผู้ใช้บริการจะส่งข้อมูลส่วนบุคคลมาที่บริการ ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์
              </p>
              <p class="t2" style="text-indent: 40px">
                โดยในการใช้บริการ ระบบงานกาชาดมะขามหวานของผู้ใช้บริการ
                ถือเป็นการยอมรับข้อตกลงในนโยบายการคุ้มครองข้อมูลส่วนบุคคลนี้
                หากผู้ใช้บริการไม่เห็นด้วยกับข้อตกลงในนโยบายการคุ้มครองข้อมูลส่วนบุคคลนี้ โปรดหยุดการใช้บริการ
                ระบบงานกาชาดมะขามหวานหากผู้ใช้บริการยังคงใช้บริการ ระบบงานกาชาดมะขามหวานนี้ ภายหลังจากที่ข้อตกลงนี้
                มีการแก้ไขและนำขึ้นประกาศในเว็บไซต์หรือบริการ ระบบงานกาชาดมะขามหวานของ จังหวัดเพชรบูรณ์แล้ว
                ให้ถือว่าผู้ใช้บริการยอมรับในการเปลี่ยนแปลงดังกล่าว
              </p>
            </li>

            <li class="level-1">
              <strong><u>การติดต่อสอบถาม</u></strong>
              <p class="t2" style="text-indent: 40px">
                หากมีข้อสงสัยเกี่ยวกับการเก็บ รวบรวม
                และใช้ข้อมูลส่วนบุคคลของผู้ใช้บริการหรือเกี่ยวกับนโยบายความเป็นส่วนตัวนี้
                ผู้ใช้บริการสามารถสอบถามได้ที่ผู้ดูแลระบบ (Admin) ของหน่วยงานของผู้ใช้บริการ หรือผ่านทาง Contact Center
                หมายเลขโทรศัพท์ 0 5672 9773 หรือทางจดหมายอิเล็กทรอนิกส์ saraban_phchabun@nso.mail.go.th หรือผ่านช่องทาง
                Line Official Account ชื่อบัญชี @phetchabun โดย จังหวัดเพชรบูรณ์
                จะพยายามอย่างเต็มที่ในการแก้ไขข้อกังวลและปัญหาต่าง ๆ
              </p>



            </li>




          </ol>



        </div>
        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <button @click="next" class="button">ยอมรับ</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
export default defineComponent({
  name: "Register",
  setup() {
    const store = inject("store");
    const router = inject("router");
    const name_line = ref(process.env.VUE_APP_NAME_LINE);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const next = () => {
      let form = {
        id_line: store.getters.getAccessToken.accesstoken,
        agreement: true,
      };
      store.dispatch(SET_REGISTER, form);
      router.push({ name: "Register_1" });
    };

    return {
      name_line,
      next,
    };
  },
});
</script>

<style scoped>
.card-body {
  flex: unset !important;
  overflow-y: auto;
  padding: 16px;
  max-height: 350px !important;
}


</style>
