<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" loader="dots" :color="color" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
          <div class="t1" style="margin: 5px">ลงทะเบียนเข้าร่วมงานกาดชาดมะขามหวาน</div>
        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
          ref="formContainer" novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <!-- {{ ProfireLine }}
              {{ data_register.gate }} -->

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ปัจจุบันท่านอาศัยอยู่จังหวัดใด</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field name="province" class="form-control  form-control-solid" as="select"
                    v-model="data_register.province" @change="checkProvince($event)">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="เพชรบูรณ์">เพชรบูรณ์</option>
                    <option v-for="(item, key) in uniqueProvince" :key="key" :value="key">
                      {{ item.province }}
                    </option>
                    <!--:selected="value && value.includes(role.id)"-->
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="province" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6" v-if="province_status">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">อำเภอ/เขต</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field name="amphoe" class="form-control  form-control-solid" as="select"
                    v-model="data_register.amphoe">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option v-for="(item, key) in uniqueAmphoe" :key="key" :value="key">
                      {{ item.amphoe }}
                    </option>
                    <!--:selected="value && value.includes(role.id)"-->
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="amphoe" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">หมายเลขโทรศัพท์ที่ติดต่อได้
                  (กรณีท่านได้รับรางวัล)</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="tel" class="form-control  form-control-solid" placeholder="เบอร์โทรศัพท์"
                    v-model="data_register.tel" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="tel" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>


            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <button type="submit" class="button">ลงทะเบียนเข้าร่วมงาน</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import liff from "@line/liff";
import iconMale from "@/assets/icon/Male.png";
import iconFemale from "@/assets/icon/Female.png";
import { defineComponent, ref, onMounted, inject, computed } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import { ErrorMessage, Field, Form } from "vee-validate";
import json from "@/service/DataStore.json";
import useSweetalert from "@/service/sweetalert2";
import * as Yup from "yup";
import useRegister from "@/service/api/register";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading
  },
  setup() {
   
    let province_status = ref(false);
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const { Sconfirm, SconfirmNc, SToast } = useSweetalert();
    const store = inject("store");
    const router = inject("router");
    let fn_status = ref(false);
    const items = ref(json);
    const data_register = store.getters.getRegister;
    const ProfireLine = store.getters.getProfireLine;
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const { Register } = useRegister();

    function checkProvince(event) {
      if (event.target.value === "เพชรบูรณ์") {
        province_status.value = true;
      } else {
        province_status.value = false;
      }
    }

    const register = Yup.object().shape({
      province: Yup.string().required("กรุณาเลือก จังหวัด").label("จังหวัด"),
      amphoe: Yup.string()
        .when("province", {
          is: (province) => province === "เพชรบูรณ์",
          then: Yup.string().required("กรุณาเลือก อำเภอ"),
        })
        .label("อำเภอ"),
      tel: Yup.string()
        .matches(phoneRegExp, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
        .min(10, "เบอร์โทรศัพท์ไม่ครบ")
        .max(10, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"),

    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const uniqueProvince = computed(() => {
      return items.value.filter(item => item.province !== "เพชรบูรณ์")
        .reduce((seed, current) => {
          return Object.assign(seed, {
            [current.province]: current,
          });
        }, {});
    });

    const uniqueAmphoe = computed(() => {
      let dd = items.value.filter(
        (item) => item.province === data_register.province
      );
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.amphoe]: current,
        });
      }, {});
    });

    const uniqueDistrict = computed(() => {
      let dd = items.value.filter(
        (item) => item.amphoe === data_register.amphoe
      );
      return dd.reduce((seed, current) => {
        return Object.assign(seed, {
          [current.district]: current,
        });
      }, {});
    });


    const monthNamesThai = ref([
      { id: "01", name: "มกราคม" },
      { id: "02", name: "กุมภาพันธ์" },
      { id: "03", name: "มีนาคม" },
      { id: "04", name: "เมษายน" },
      { id: "05", name: "พฤษภาคม" },
      { id: "06", name: "มิถุนายน" },
      { id: "07", name: "กรกฎาคม" },
      { id: "08", name: "สิงหาคม" },
      { id: "09", name: "กันยายน" },
      { id: "10", name: "ตุลาคม" },
      { id: "11", name: "พฤศจิกายน" },
      { id: "12", name: "ธันวาคม" },
    ]);

    const setday = (day, month, year) => {
      let y = year;
      let m = month;
      let d = day;
      return y + "-" + m + "-" + d;
    };


    const onSubmitRegister = (values) => {
      values.line_name = ProfireLine.displayName;
      store.dispatch(SET_REGISTER, values);
      console.log(store.getters.getRegister);

      Sconfirm("ยืนยัน การลงทะเบียน", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Register(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {
                  if (store.getters.getUrlCallBack.id) {
                    liff.ready.then(() => {
                      liff.openWindow({
                        url: store.getters.getUrlCallBack.id,
                        external: false,
                      });
                    });
                  } else {
                    router.push({ name: "Home" });
                  }
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data.errors;

                if (errors.id_line) {
                  SToast("error", `${errors.id_line[0]}`);
                } else {
                  SToast("error", "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง");
                }
              }
            });
        }
      });
    };

    function checkFn(event) {
      if (event.target.value === "อื่นๆ") {
        fn_status.value = true;
      } else {
        fn_status.value = false;
      }
    }

    return {
      onSubmitRegister,
      register,
      checkFn,
      fn_status,
      iconMale,
      iconFemale,
      data_register,
      setday,
      monthNamesThai,
      uniqueAmphoe,
      uniqueDistrict,
      uniqueProvince,
      color,
      isLoading,
      province_status,
      checkProvince,
      ProfireLine
    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}

.icon {
  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

input[type="radio"] {
  display: none;
}

input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}
</style>
